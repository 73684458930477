import React from 'react'
import Helmet from 'react-helmet'

import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import './all.scss'
import '@fortawesome/fontawesome-free/js/all.js'

class TemplateWrapper extends React.Component {
  constructor(props) {
    super(props);
    
    var startingScroll = typeof document !== 'undefined' && document.documentElement.scrollTop > 0
    this.state = {
      isScrolled: startingScroll,
      isLoaded: false
    };
  }
  
  componentDidMount() {
    window.addEventListener("scroll", (e) => {
      const newScrolled = document.documentElement.scrollTop > 0;
      
      if (newScrolled !== this.state.isScrolled) {
        this.setState({
          isScrolled: newScrolled,
          isLoaded: true
        });
      }
    });
    
    window.addEventListener("load", (e) => {
       this.setState({
          isLoaded: true
        });
    });
  }

  render() {
    const { children, addScrolledClass } = this.props;

    return <div className="layout-main">
      <Helmet
        htmlAttributes={{ lang: 'en' }}
        
        meta={[{
          name: 'description',
          content: 'Voices Across Time'
        },{
          name: 'viewport',
          content: 'width=device-width, initial-scale=1, maximum-scale=1.0, user-scalable=no'
        }]}
        
        title='Voices Across Time'
        link={[{
          href:'https://cdn.snipcart.com/themes/2.0/base/snipcart.min.css',
          rel:'stylesheet',
          type:"text/css" 
        }]}
        script={[{
          type: 'text/javascript',
          src: 'https://ajax.googleapis.com/ajax/libs/jquery/2.2.2/jquery.min.js'
        },{ 
          type: 'text/javascript', 
          url:'',
          id: 'snipcart',
          'data-api-key': 'YzQwZDA1ZjAtYjUyOS00ZTZmLTgwNzItZDk3NThkZWM3YmNkNjM2ODg2MjgyNjkwMDQ0NjM4',
          src:'https://cdn.snipcart.com/scripts/2.0/snipcart.js'
        }]}
        
        bodyAttributes={{
          class: (addScrolledClass ? 'scroll-navbar ' + (this.state.isScrolled ? 'scrolled ': 'not-scrolled '): 'has-navbar-fixed-top ') + (this.state.isLoaded ? 'animate-navbar' : 'stop-navbar-animation')
        }}
      />
  
      <Navbar />
      <div className="content-main">{children}</div>
      <Footer />
    </div>
  }
}

export default TemplateWrapper
