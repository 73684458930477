import React from 'react'
import ContentPadding from '../components/ContentPadding'

const ColoredHeader = ({ children }) => (
  <section className="section has-background-dark-color colored-bar">
    <ContentPadding>
      <h1 className="is-size-2 has-text-white is-marginless has-text-weight-normal">{children}</h1>
    </ContentPadding>
  </section>
)

export default ColoredHeader
