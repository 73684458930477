import React from 'react'

const ContentPadding = ({ children }) => (
  <div className="container">
    <div className="columns">
        <div className="content column is-offset-1 is-10">
            {children}
        </div>
    </div>
  </div>
)

export default ContentPadding
