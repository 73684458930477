import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import remark from 'remark'
import recommended from 'remark-preset-lint-recommended';
import remarkHtml from 'remark-html'
import ContentPadding from '../components/ContentPadding'
import Img from 'gatsby-image'
import ColoredHeader from '../components/ColoredHeader'
import Helmet from 'react-helmet'

/* eslint-disable */
class FriendsPage extends React.Component {
  constructor(props) {
    super(props);
    this.handleLoad = this.handleLoad.bind(this);
  }
 
  componentDidMount = () => {
    if (typeof Snipcart == 'object') {
      this.handleLoad();  
    } else {
      document.addEventListener('snipcart.ready', this.handleLoad);
    }
    
  }
  
  handleLoad() {
    console.log('handle load called', typeof $);
    //horrible page load hacks!
    if (typeof $ == 'function' && typeof Snipcart == 'object') {
      const params = new URLSearchParams(window.location.search);  
      
      const renew = params.get('renew');
      var regex = RegExp('^[a-z\-]+$');
      
      console.log('ahah', renew, regex.test(renew));
      
      if (renew && regex.test(renew)) {
        var button = $(`.snipcart-add-item[data-item-id="${renew}"]`).first();
        
        console.log(button, `.snipcart-add-item[data-item-id="${renew}"]`);
        
        if (button && button.length) {
          $(document.documentElement).scrollTop(button.offset().top - 500);
          button.click();
        }
      }
    } else {
      console.log('jquery not loaded');
      setTimeout(this.handleLoad, 100);
    }
  }
  
  render = () => {
    const { markdownRemark: post } = this.props.data;
    const page = post.frontmatter;
    const PageContent = HTMLContent || Content;
    
    const remarkProcessor = remark().use(recommended).use(remarkHtml);
    
    const introContent = remarkProcessor.processSync(page.intro).toString();
    const friendsIntro = remarkProcessor.processSync(page.friends_intro).toString();
    const donateIntro = remarkProcessor.processSync(page.donate_intro).toString();
    const giftsBody = remarkProcessor.processSync(page.gifts_body).toString();
    
    var donateString = null;
    var donateDefault = 50;
    
    if (page.donation_levels && page.donation_levels.length) {
      donateString = page.donation_levels.map(d => {
        const diff = d.amount - donateDefault;
        
        return '£' + d.amount + (diff ? '[' + (diff > 0 ? '+' : '') + diff + ']' : '')
      }).join('|');
    }
  
    return (
      <Layout>
        <Helmet title="Voices Across Time | Support Us" />
        <Img className="header-img" fluid={page.header_image.childImageSharp.fluid} alt="" />
        <ContentPadding>
          <div className="section">
            <h2 className="title is-size-3 has-text-weight-bold is-bold-light">
              {page.title}
            </h2>
            
            <PageContent className="content" content={introContent} />
          </div>
        </ContentPadding>
  
        <a className="anchor" name="friends"></a>
        <ColoredHeader>{page.friends_title}</ColoredHeader>
            
        <ContentPadding>
          <div className="section">    
            <PageContent className="content" content={friendsIntro} />
            
            {page.friends_levels.map((a,i) => {
              var content = remark().use(recommended).use(remarkHtml).processSync(a.description).toString();
  
              return <div key={i} className="content">
                  <h4 className="title is-size-5 is-bold-light">
                    {a.title}
                  </h4>
                  <PageContent className="content" content={content} />
                  <div>Cost: £{a.cost}</div>
                  <br />
                  <button className="snipcart-add-item button"
                    data-item-name={a.title}
                    data-item-id={a.title.replace(/\s/g, '-').replace(/[^a-zA-Z-]/g, '').toLowerCase()}
                    data-item-price={a.cost}
                    data-item-payment-interval="Year"
                    data-item-url="/support-us"
                    
                    data-item-shippable={false}
                  >Join Now!</button>
                </div>
            })}
  
            Already a friend? Click <a className="snipcart-user-profile">here</a> to login and manage your subscription.
          </div>
        </ContentPadding>
            
        <a className="anchor" name="donate"></a>
        <ColoredHeader>{page.donate_title}</ColoredHeader>
            
        <ContentPadding>
          <div className="section">
            <PageContent className="content" content={donateIntro} />
            
            <button className="snipcart-add-item button"
                    data-item-name="Donation"
                    data-item-id="donation"
                    data-item-price={donateDefault}
                    data-item-url="/support-us"
                    
                    data-item-custom1-name={donateString ? 'Amount' : null}
                    data-item-custom1-options={donateString}
                  >Donate Now!</button>
          </div>
        </ContentPadding>
            
        <a className="anchor" name="gifts-in-kind"></a>
        <ColoredHeader>{page.gifts_title}</ColoredHeader>
        
        <ContentPadding>
          <div className="section">
            <PageContent className="content" content={giftsBody} />
          </div>
        </ContentPadding>
      </Layout>
    )
  }
}

export default FriendsPage

export const FriendsPageQuery = graphql`
  query FriendsPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        templateKey
        header_image {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 75, toFormat: JPG) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        intro
        friends_title
        friends_intro
        friends_levels{
          title
          cost
          description
        }
        donate_title
        donate_intro
        donation_levels{
          amount
        }
        gifts_title
        gifts_body
      }
    }
  }
`
