/* eslint-disable */
import React from 'react'
import { Link } from 'gatsby'
import logo from '../img/logo.png'
import { StaticQuery, graphql } from "gatsby"

class Navbar extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      menuActive: false
    }
    
     this.toggleMenu = this.toggleMenu.bind(this);
  }

  toggleMenu() {
    this.setState({
      menuActive: !this.state.menuActive
    })
  }
  
  render() {
    return <nav className={(this.state.menuActive ? 'menu-is-active ' : '') + 'navbar is-fixed-top'}>
              <div className="container">
                <div className="navbar-brand">
                  <Link to="/" className="navbar-item" >
                      <img src={logo} alt="Voices Across Time" />
                  </Link>
                  <a className={(this.state.menuActive ? 'is-active ' : '') + 'navbar-burger is-large'} aria-label="menu" aria-expanded="false" onClick={this.toggleMenu}>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                  </a>
                </div>
                <div className={(this.state.menuActive ? 'is-active ' : '') + 'navbar-menu'}>
                  <div className="navbar-end is-size-4">
                    <div className="navbar-item has-dropdown is-hoverable">
                      <Link className="navbar-item " to="/about">
                      About
                      </Link>
                      <div className="navbar-dropdown is-right">
                        <Link className="navbar-item" to="/about#team">
                          Team
                        </Link>
                      </div>
                    </div>
                    <div className="navbar-item has-dropdown is-hoverable">
                      <Link className="navbar-item" to="/shows">
                        Shows
                      </Link>
                      <div className="navbar-dropdown is-right">
                        {this.props.shows.edges.map(({ node: show }, ) => (
                          <Link key={show.fields.slug} className="navbar-item" to={show.fields.slug}>
                            {show.frontmatter.title}
                          </Link>
                        ))}
                      </div>
                    </div>
                    <Link className="navbar-item" to="/outreach">
                      Outreach
                    </Link>
                    <div className="navbar-item has-dropdown is-hoverable">
                      <Link className="navbar-item" to="/support-us">
                        Support Us
                      </Link>
                      <div className="navbar-dropdown is-right">
                        <Link className="navbar-item" to="/support-us/shop">
                          Shop
                        </Link>
                        <Link className="navbar-item" to="/support-us#friends">
                          Friends
                        </Link>
                        <Link className="navbar-item" to="/support-us#donate">
                          Donate
                        </Link>
                        <Link className="navbar-item" to="/support-us#gifts-in-kind">
                          Gifts In Kind
                        </Link>
                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </nav>
  }
}


export default () => (
  <StaticQuery
    query={graphql`
      query NavbarQuery {
        shows: allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "show" }, published: { eq: true } } }
        ) {
          edges {
            node {
              id
              fields {
                slug
              }
              frontmatter {
                title
              }
            }
          }
        }
      }
    `}
    render={data => (
      <Navbar shows={data.shows} />
    )}
  />
)
