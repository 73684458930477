/* eslint-disable */
import React from 'react'
import { Link } from 'gatsby'
import LargeLogo from '../img/logosquare-white.png'
import AceLogo from '../img/grant_png_white.png'
import ContentPadding from '../components/ContentPadding'
import { TwitterTimelineEmbed } from 'react-twitter-embed';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter, faFacebook, faInstagram  } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope, faPhone  } from '@fortawesome/free-solid-svg-icons'

const Footer = () => (
  <footer className="footer has-text-white">
    <div className="container">
      <div className="columns">
        <div className="column content">
          <img src={LargeLogo} style={{maxWidth: '320px', width: '100%', margin: 'auto', display: 'block'}}/>
          
          <a href="https://www.artscouncil.org.uk/" target="_blank"><img src={AceLogo} style={{maxWidth: '320px', width: '100%', margin: 'auto', display: 'block'}}/></a>
        </div>
        <div className="column content" style={{paddingLeft: '30px'}}>
          <p>
            <a className="footer-link" href="https://www.facebook.com/VATvoicesacrosstime">
              <span className="icon footer-icon">
                <FontAwesomeIcon size="lg" icon={faFacebook} />
              </span>
              VATvoicesacrosstime
            </a>
          </p>
          <p>
            <a className="footer-link" href="https://twitter.com/voicesatime">
              <span className="icon footer-icon">
                <FontAwesomeIcon size="lg" icon={faTwitter} />
              </span>
              @voicesatime
            </a>
          </p>
          <p>
            <a className="footer-link" href="https://www.instagram.com/voicesacrosstime/">
              <span className="icon footer-icon">
                <FontAwesomeIcon size="lg" icon={faInstagram} />
              </span>
              @voicesacrosstime
            </a>
          </p>
          <p>
            <a className="footer-link" href="mailto:boxoffice@voicesacrosstime.com">
              <span className="icon footer-icon">
                <FontAwesomeIcon size="lg" icon={faEnvelope} />
              </span>
              boxoffice@voicesacrosstime.com
            </a>
          </p>
          <p>
            <a className="footer-link" href="tel:07532446665">
              <span className="icon footer-icon">
                <FontAwesomeIcon size="lg" icon={faPhone} />
              </span>
              07532 446665
            </a>
          </p>
          <p>
            <a className="footer-link" href="https://wordpress.us14.list-manage.com/subscribe?u=01bd39baef22078169e9575ab&id=e305351e9e" target="_blank">Join our Mailing List</a>
          </p>
          <p>
            <a className="footer-link" href="https://james.willmoth.co.uk">Site by James Willmoth</a>
          </p>
        </div>
        <div className="column content has-centered-content">
          <TwitterTimelineEmbed
            sourceType="profile"
            screenName="voicesatime"
            theme="dark"
            options={{height: 400}}
          />
        </div>
      </div>
    </div>
  </footer>
)

export default Footer
